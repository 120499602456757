import { FC, ReactNode } from "react";
import Header from "./header";

interface ExternalHeaderProps {
  title: ReactNode;
}

const ExternalHeader: FC<ExternalHeaderProps> = ({ title }) => {
  return (
    <>
      <Header>The Focus Project</Header>
      <Header size="h2">{title}</Header>
    </>
  );
};

export default ExternalHeader;
