import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { isChrome, isMobile } from "react-device-detect";
import { Button } from "@heroui/react";

import { useAppSelector } from "./hooks/store";
import { FocusNavbar } from "./components/navbar/navbar";
import InstallChrome from "./components/install/installChrome";
import InstallMobileApp from "./components/install/installMobileApp";
import Login from "./components/login/login";
import { SessionRoute } from "./types/session";
import ErrorMessage from "./pages/errorMessage";
import TermsModal from "./components/termsModal/termsModal";
import { usePanelistDetails } from "./api";
import packageInfo from "../package.json";

const Root = () => {
  const { loggedIn, panelist, panelistId, accessCode } = useAppSelector(
    (state) => state.auth,
  );
  const { pathname } = useLocation();
  const isSessionRoute =
    Object.values(SessionRoute).includes(pathname as SessionRoute) && loggedIn;
  const isHome = pathname === "/";
  const externalOnlyPaths = [
    "/account-admin/sign-up",
    "/account-admin/forgot-password",
    "/account-admin/reset-password",
    "/account-admin/create-password",
  ];
  const globallyAvailablePaths = [
    "/data-deletion-request",
    "/terms-and-privacy",
  ];
  const allExternalPaths = externalOnlyPaths.concat(globallyAvailablePaths);
  const [{ data: panelistDetails, loading }, refetch] = usePanelistDetails(
    accessCode,
    panelistId,
  );

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    (window as any).userpilot.identify(panelist);
  }, [panelist]);

  useEffect(() => {
    if (!panelistDetails || !panelistDetails?.success || loading) return;

    setIsOpen(!panelistDetails?.has_agreed_terms);
  }, [panelistDetails, loading]);

  const getContent = () => {
    if (!loggedIn && !allExternalPaths.includes(pathname)) return <Login />;
    if (loggedIn && externalOnlyPaths.includes(pathname))
      return <ErrorMessage />;
    if (isSessionRoute && isMobile) return <InstallMobileApp />;

    if (isSessionRoute && !isChrome) return <InstallChrome />;
    return <Outlet />;
  };

  return (
    <>
      <div className="hidden" id="version">
        {packageInfo.version}
      </div>
      <div className="h-screen relative w-screen flex flex-col">
        {loggedIn ? (
          <FocusNavbar />
        ) : (
          <Button
            className={`${isMobile ? "block mt-5 -mb-5 text-right" : "absolute top-5 right-5"} z-50 data-[hover=true]:bg-white`}
            onPress={() =>
              (window as any).userpilot.trigger("survey:nvpw3IPTI9")
            }
            color="primary"
            variant="light"
          >
            Contact Us
          </Button>
        )}
        <main className="light text-foreground bg-background relative p-8 lg:px-16 flex-1 overflow-y-auto">
          <div
            className={`${isSessionRoute && isChrome ? "" : "flex justify-center"}`}
          >
            <div
              className={`${isSessionRoute && isChrome ? "flex flex-col lg:flex-row gap-10 lg:gap-0" : "w-full md:w-3/4 lg:w-1/2"}`}
            >
              {getContent()}
            </div>
          </div>
        </main>
      </div>
      {(!isSessionRoute || isHome) && (
        <TermsModal isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} />
      )}
    </>
  );
};

export default Root;
