/* eslint-disable @typescript-eslint/no-throw-literal */
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@heroui/react";

import { useAppSelector } from "@/hooks/store";
import { SessionValidationContext, SessionRoute } from "@/types/session";
import { submitWildValidationRequest } from "@/api/index";
import { ValidationResult } from "@/types/rni";
import { ErrorClass } from "@/utils/errorFormat";
import { useSessionErrorContext } from "@/contexts/sessionErrorContext";
import Header from "@/components/header/header";

const SessionValidation = () => {
  const {
    broker: { trackersController, turnOffCamera, turnOnCamera, validate },
    validationSessionDetails,
    validationRef,
    trackerRef,
    errorHandle,
    setStatus,
  } = useOutletContext<SessionValidationContext>();
  const { getError } = useSessionErrorContext();
  const navigate = useNavigate();
  const maxAcc = useAppSelector((state) => state.auth.maxAcc);

  const startValidation = async () => {
    try {
      const div = validationRef.current;
      const trackerDiv = trackerRef.current;
      if (!div || !trackerDiv) {
        const error = getError(ErrorClass.VALIDATION_DIV);
        throw error;
      }

      trackersController._videoView.releaseDivElement();
      const validation: ValidationResult = await validate(div, 50000);

      setStatus((value) => ({
        ...value,
        validation: validation.validatedErrorCorrection._accuracy,
      }));

      if (validation.validatedErrorCorrection._accuracy > maxAcc) {
        const error = getError(ErrorClass.VALIDATION_FAIL);

        throw error;
      }

      await submitWildValidationRequest(
        validation,
        validationSessionDetails.panelist,
        validationSessionDetails.panelistId,
        validationSessionDetails.tmpSessionId,
        validationSessionDetails.calibrationTime,
      );
      trackersController.setVideoDiv(trackerDiv);
      trackersController._videoView.render();
      navigate(SessionRoute.EXTERNAL);
    } catch (e) {
      const div = validationRef.current;
      const trackerDiv = trackerRef.current;
      if (div) {
        div.style.display = "none";
      }

      turnOffCamera();

      if (e.errorClass === ErrorClass.VALIDATION_FAIL) {
        const action = () => {
          trackersController.setVideoDiv(trackerDiv);
          trackersController._videoView.render();
          trackersController._detectorCalibrator.reset();
          trackersController._gazeValidator.reset();
          turnOnCamera();
        };
        navigate(SessionRoute.CALIBRATION);
        errorHandle(e, action);
      } else {
        errorHandle(e);
      }
    }
  };

  return (
    <>
      <Header size="h2">Step 4: Validation</Header>
      <div className="flex flex-col gap-5 mb-8">
        <p>On the next page follow the green dot with your eyes.</p>
        <p>Try to keep your head still.</p>
      </div>
      <Button color="secondary" onPress={startValidation}>
        Continue
      </Button>
    </>
  );
};

export default SessionValidation;
