"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Module = (function () {
    var _a;
    var _scriptName = typeof document != 'undefined' ? (_a = document.currentScript) === null || _a === void 0 ? void 0 : _a.src : undefined;
    return (function (moduleArg) {
        if (moduleArg === void 0) { moduleArg = {}; }
        var moduleRtn;
        var Module = moduleArg;
        var readyPromiseResolve, readyPromiseReject;
        var readyPromise = new Promise(function (resolve, reject) { readyPromiseResolve = resolve; readyPromiseReject = reject; });
        var ENVIRONMENT_IS_WEB = typeof window == "object";
        var ENVIRONMENT_IS_WORKER = typeof importScripts == "function";
        var ENVIRONMENT_IS_NODE = typeof process == "object" && typeof process.versions == "object" && typeof process.versions.node == "string";
        var moduleOverrides = Object.assign({}, Module);
        var arguments_ = [];
        var thisProgram = "./this.program";
        var quit_ = function (status, toThrow) { throw toThrow; };
        var scriptDirectory = "";
        function locateFile(path) { if (Module["locateFile"]) {
            return Module["locateFile"](path, scriptDirectory);
        } return scriptDirectory + path; }
        var readAsync, readBinary;
        if (ENVIRONMENT_IS_WEB || ENVIRONMENT_IS_WORKER) {
            if (ENVIRONMENT_IS_WORKER) {
                scriptDirectory = self.location.href;
            }
            else if (typeof document != "undefined" && document.currentScript) {
                scriptDirectory = document.currentScript.src;
            }
            if (_scriptName) {
                scriptDirectory = _scriptName;
            }
            if (scriptDirectory.startsWith("blob:")) {
                scriptDirectory = "";
            }
            else {
                scriptDirectory = scriptDirectory.substr(0, scriptDirectory.replace(/[?#].*/, "").lastIndexOf("/") + 1);
            }
            {
                if (ENVIRONMENT_IS_WORKER) {
                    readBinary = function (url) { var xhr = new XMLHttpRequest; xhr.open("GET", url, false); xhr.responseType = "arraybuffer"; xhr.send(null); return new Uint8Array(xhr.response); };
                }
                readAsync = function (url) { if (isFileURI(url)) {
                    return new Promise(function (reject, resolve) { var xhr = new XMLHttpRequest; xhr.open("GET", url, true); xhr.responseType = "arraybuffer"; xhr.onload = function () { if (xhr.status == 200 || xhr.status == 0 && xhr.response) {
                        resolve(xhr.response);
                    } reject(xhr.status); }; xhr.onerror = reject; xhr.send(null); });
                } return fetch(url, { credentials: "same-origin" }).then(function (response) { if (response.ok) {
                    return response.arrayBuffer();
                } return Promise.reject(new Error(response.status + " : " + response.url)); }); };
            }
        }
        else { }
        var out = Module["print"] || console.log.bind(console);
        var err = Module["printErr"] || console.error.bind(console);
        Object.assign(Module, moduleOverrides);
        moduleOverrides = null;
        if (Module["arguments"])
            arguments_ = Module["arguments"];
        if (Module["thisProgram"])
            thisProgram = Module["thisProgram"];
        if (Module["quit"])
            quit_ = Module["quit"];
        var wasmBinary;
        if (Module["wasmBinary"])
            wasmBinary = Module["wasmBinary"];
        var wasmMemory;
        var ABORT = false;
        var EXITSTATUS;
        var HEAP8, HEAPU8, HEAP16, HEAPU16, HEAP32, HEAPU32, HEAPF32, HEAPF64;
        function updateMemoryViews() { var b = wasmMemory.buffer; Module["HEAP8"] = HEAP8 = new Int8Array(b); Module["HEAP16"] = HEAP16 = new Int16Array(b); Module["HEAPU8"] = HEAPU8 = new Uint8Array(b); Module["HEAPU16"] = HEAPU16 = new Uint16Array(b); Module["HEAP32"] = HEAP32 = new Int32Array(b); Module["HEAPU32"] = HEAPU32 = new Uint32Array(b); Module["HEAPF32"] = HEAPF32 = new Float32Array(b); Module["HEAPF64"] = HEAPF64 = new Float64Array(b); }
        var __ATPRERUN__ = [];
        var __ATINIT__ = [];
        var __ATPOSTRUN__ = [];
        var runtimeInitialized = false;
        function preRun() { if (Module["preRun"]) {
            if (typeof Module["preRun"] == "function")
                Module["preRun"] = [Module["preRun"]];
            while (Module["preRun"].length) {
                addOnPreRun(Module["preRun"].shift());
            }
        } callRuntimeCallbacks(__ATPRERUN__); }
        function initRuntime() { runtimeInitialized = true; callRuntimeCallbacks(__ATINIT__); }
        function postRun() { if (Module["postRun"]) {
            if (typeof Module["postRun"] == "function")
                Module["postRun"] = [Module["postRun"]];
            while (Module["postRun"].length) {
                addOnPostRun(Module["postRun"].shift());
            }
        } callRuntimeCallbacks(__ATPOSTRUN__); }
        function addOnPreRun(cb) { __ATPRERUN__.unshift(cb); }
        function addOnInit(cb) { __ATINIT__.unshift(cb); }
        function addOnPostRun(cb) { __ATPOSTRUN__.unshift(cb); }
        var runDependencies = 0;
        var runDependencyWatcher = null;
        var dependenciesFulfilled = null;
        function addRunDependency(id) { var _a; runDependencies++; (_a = Module["monitorRunDependencies"]) === null || _a === void 0 ? void 0 : _a.call(Module, runDependencies); }
        function removeRunDependency(id) { var _a; runDependencies--; (_a = Module["monitorRunDependencies"]) === null || _a === void 0 ? void 0 : _a.call(Module, runDependencies); if (runDependencies == 0) {
            if (runDependencyWatcher !== null) {
                clearInterval(runDependencyWatcher);
                runDependencyWatcher = null;
            }
            if (dependenciesFulfilled) {
                var callback = dependenciesFulfilled;
                dependenciesFulfilled = null;
                callback();
            }
        } }
        function abort(what) { var _a; (_a = Module["onAbort"]) === null || _a === void 0 ? void 0 : _a.call(Module, what); what = "Aborted(" + what + ")"; err(what); ABORT = true; EXITSTATUS = 1; what += ". Build with -sASSERTIONS for more info."; var e = new WebAssembly.RuntimeError(what); readyPromiseReject(e); throw e; }
        var dataURIPrefix = "data:application/octet-stream;base64,";
        var isDataURI = function (filename) { return filename.startsWith(dataURIPrefix); };
        var isFileURI = function (filename) { return filename.startsWith("file://"); };
        function findWasmBinary() { var f = "lumen_tracker_main.wasm"; if (!isDataURI(f)) {
            return locateFile(f);
        } return f; }
        var wasmBinaryFile;
        function getBinarySync(file) { if (file == wasmBinaryFile && wasmBinary) {
            return new Uint8Array(wasmBinary);
        } if (readBinary) {
            return readBinary(file);
        } throw "both async and sync fetching of the wasm failed"; }
        function getBinaryPromise(binaryFile) { if (!wasmBinary) {
            return readAsync(binaryFile).then(function (response) { return new Uint8Array(response); }, function () { return getBinarySync(binaryFile); });
        } return Promise.resolve().then(function () { return getBinarySync(binaryFile); }); }
        function instantiateArrayBuffer(binaryFile, imports, receiver) { return getBinaryPromise(binaryFile).then(function (binary) { return WebAssembly.instantiate(binary, imports); }).then(receiver, function (reason) { err("failed to asynchronously prepare wasm: ".concat(reason)); abort(reason); }); }
        function instantiateAsync(binary, binaryFile, imports, callback) { if (!binary && typeof WebAssembly.instantiateStreaming == "function" && !isDataURI(binaryFile) && !isFileURI(binaryFile) && typeof fetch == "function") {
            return fetch(binaryFile, { credentials: "same-origin" }).then(function (response) { var result = WebAssembly.instantiateStreaming(response, imports); return result.then(callback, function (reason) { err("wasm streaming compile failed: ".concat(reason)); err("falling back to ArrayBuffer instantiation"); return instantiateArrayBuffer(binaryFile, imports, callback); }); });
        } return instantiateArrayBuffer(binaryFile, imports, callback); }
        function getWasmImports() { return { env: wasmImports, wasi_snapshot_preview1: wasmImports }; }
        function createWasm() { var info = getWasmImports(); function receiveInstance(instance, module) { wasmExports = instance.exports; Module["asm"] = wasmExports; wasmMemory = wasmExports["memory"]; updateMemoryViews(); wasmTable = wasmExports["__indirect_function_table"]; addOnInit(wasmExports["__wasm_call_ctors"]); removeRunDependency("wasm-instantiate"); return wasmExports; } addRunDependency("wasm-instantiate"); function receiveInstantiationResult(result) { receiveInstance(result["instance"]); } if (Module["instantiateWasm"]) {
            try {
                return Module["instantiateWasm"](info, receiveInstance);
            }
            catch (e) {
                err("Module.instantiateWasm callback failed with error: ".concat(e));
                readyPromiseReject(e);
            }
        } if (!wasmBinaryFile)
            wasmBinaryFile = findWasmBinary(); instantiateAsync(wasmBinary, wasmBinaryFile, info, receiveInstantiationResult).catch(readyPromiseReject); return {}; }
        var ASM_CONSTS = { 2564268: function () { return Module.getRandomValue(); }, 2564304: function () { if (Module.getRandomValue === undefined) {
                try {
                    var window_ = "object" === typeof window ? window : self;
                    var crypto_ = typeof window_.crypto !== "undefined" ? window_.crypto : window_.msCrypto;
                    var randomValuesStandard = function () { var buf = new Uint32Array(1); crypto_.getRandomValues(buf); return buf[0] >>> 0; };
                    randomValuesStandard();
                    Module.getRandomValue = randomValuesStandard;
                }
                catch (e) {
                    try {
                        var crypto = require("crypto");
                        var randomValueNodeJS = function () { var buf = crypto["randomBytes"](4); return (buf[0] << 24 | buf[1] << 16 | buf[2] << 8 | buf[3]) >>> 0; };
                        randomValueNodeJS();
                        Module.getRandomValue = randomValueNodeJS;
                    }
                    catch (e) {
                        throw "No secure random number generator found";
                    }
                }
            } } };
        function ExitStatus(status) { this.name = "ExitStatus"; this.message = "Program terminated with exit(".concat(status, ")"); this.status = status; }
        var callRuntimeCallbacks = function (callbacks) { while (callbacks.length > 0) {
            callbacks.shift()(Module);
        } };
        var noExitRuntime = Module["noExitRuntime"] || true;
        var stackRestore = function (val) { return __emscripten_stack_restore(val); };
        var stackSave = function () { return _emscripten_stack_get_current(); };
        var exceptionCaught = [];
        var uncaughtExceptionCount = 0;
        var ___cxa_begin_catch = function (ptr) { var info = new ExceptionInfo(ptr); if (!info.get_caught()) {
            info.set_caught(true);
            uncaughtExceptionCount--;
        } info.set_rethrown(false); exceptionCaught.push(info); ___cxa_increment_exception_refcount(info.excPtr); return info.get_exception_ptr(); };
        var exceptionLast = 0;
        var ___cxa_end_catch = function () { _setThrew(0, 0); var info = exceptionCaught.pop(); ___cxa_decrement_exception_refcount(info.excPtr); exceptionLast = 0; };
        var ExceptionInfo = /** @class */ (function () {
            function ExceptionInfo(excPtr) {
                this.excPtr = excPtr;
                this.ptr = excPtr - 24;
            }
            ExceptionInfo.prototype.set_type = function (type) { HEAPU32[this.ptr + 4 >> 2] = type; };
            ExceptionInfo.prototype.get_type = function () { return HEAPU32[this.ptr + 4 >> 2]; };
            ExceptionInfo.prototype.set_destructor = function (destructor) { HEAPU32[this.ptr + 8 >> 2] = destructor; };
            ExceptionInfo.prototype.get_destructor = function () { return HEAPU32[this.ptr + 8 >> 2]; };
            ExceptionInfo.prototype.set_caught = function (caught) { caught = caught ? 1 : 0; HEAP8[this.ptr + 12] = caught; };
            ExceptionInfo.prototype.get_caught = function () { return HEAP8[this.ptr + 12] != 0; };
            ExceptionInfo.prototype.set_rethrown = function (rethrown) { rethrown = rethrown ? 1 : 0; HEAP8[this.ptr + 13] = rethrown; };
            ExceptionInfo.prototype.get_rethrown = function () { return HEAP8[this.ptr + 13] != 0; };
            ExceptionInfo.prototype.init = function (type, destructor) { this.set_adjusted_ptr(0); this.set_type(type); this.set_destructor(destructor); };
            ExceptionInfo.prototype.set_adjusted_ptr = function (adjustedPtr) { HEAPU32[this.ptr + 16 >> 2] = adjustedPtr; };
            ExceptionInfo.prototype.get_adjusted_ptr = function () { return HEAPU32[this.ptr + 16 >> 2]; };
            ExceptionInfo.prototype.get_exception_ptr = function () { var isPointer = ___cxa_is_pointer_type(this.get_type()); if (isPointer) {
                return HEAPU32[this.excPtr >> 2];
            } var adjusted = this.get_adjusted_ptr(); if (adjusted !== 0)
                return adjusted; return this.excPtr; };
            return ExceptionInfo;
        }());
        var ___resumeException = function (ptr) { if (!exceptionLast) {
            exceptionLast = ptr;
        } throw exceptionLast; };
        var setTempRet0 = function (val) { return __emscripten_tempret_set(val); };
        var findMatchingCatch = function (args) { var thrown = exceptionLast; if (!thrown) {
            setTempRet0(0);
            return 0;
        } var info = new ExceptionInfo(thrown); info.set_adjusted_ptr(thrown); var thrownType = info.get_type(); if (!thrownType) {
            setTempRet0(0);
            return thrown;
        } for (var _i = 0, args_1 = args; _i < args_1.length; _i++) {
            var caughtType = args_1[_i];
            if (caughtType === 0 || caughtType === thrownType) {
                break;
            }
            var adjusted_ptr_addr = info.ptr + 16;
            if (___cxa_can_catch(caughtType, thrownType, adjusted_ptr_addr)) {
                setTempRet0(caughtType);
                return thrown;
            }
        } setTempRet0(thrownType); return thrown; };
        var ___cxa_find_matching_catch_2 = function () { return findMatchingCatch([]); };
        var ___cxa_find_matching_catch_3 = function (arg0) { return findMatchingCatch([arg0]); };
        var ___cxa_find_matching_catch_4 = function (arg0, arg1) { return findMatchingCatch([arg0, arg1]); };
        var ___cxa_throw = function (ptr, type, destructor) { var info = new ExceptionInfo(ptr); info.init(type, destructor); exceptionLast = ptr; uncaughtExceptionCount++; throw exceptionLast; };
        var ___syscall_dup = function (fd) { };
        var ___syscall_faccessat = function (dirfd, path, amode, flags) { };
        var UTF8Decoder = typeof TextDecoder != "undefined" ? new TextDecoder : undefined;
        var UTF8ArrayToString = function (heapOrArray, idx, maxBytesToRead) { var endIdx = idx + maxBytesToRead; var endPtr = idx; while (heapOrArray[endPtr] && !(endPtr >= endIdx))
            ++endPtr; if (endPtr - idx > 16 && heapOrArray.buffer && UTF8Decoder) {
            return UTF8Decoder.decode(heapOrArray.subarray(idx, endPtr));
        } var str = ""; while (idx < endPtr) {
            var u0 = heapOrArray[idx++];
            if (!(u0 & 128)) {
                str += String.fromCharCode(u0);
                continue;
            }
            var u1 = heapOrArray[idx++] & 63;
            if ((u0 & 224) == 192) {
                str += String.fromCharCode((u0 & 31) << 6 | u1);
                continue;
            }
            var u2 = heapOrArray[idx++] & 63;
            if ((u0 & 240) == 224) {
                u0 = (u0 & 15) << 12 | u1 << 6 | u2;
            }
            else {
                u0 = (u0 & 7) << 18 | u1 << 12 | u2 << 6 | heapOrArray[idx++] & 63;
            }
            if (u0 < 65536) {
                str += String.fromCharCode(u0);
            }
            else {
                var ch = u0 - 65536;
                str += String.fromCharCode(55296 | ch >> 10, 56320 | ch & 1023);
            }
        } return str; };
        var UTF8ToString = function (ptr, maxBytesToRead) { return ptr ? UTF8ArrayToString(HEAPU8, ptr, maxBytesToRead) : ""; };
        var SYSCALLS = { varargs: undefined, getStr: function (ptr) { var ret = UTF8ToString(ptr); return ret; } };
        function ___syscall_fcntl64(fd, cmd, varargs) { SYSCALLS.varargs = varargs; return 0; }
        var ___syscall_fstat64 = function (fd, buf) { };
        function ___syscall_ioctl(fd, op, varargs) { SYSCALLS.varargs = varargs; return 0; }
        var ___syscall_lstat64 = function (path, buf) { };
        var ___syscall_newfstatat = function (dirfd, path, buf, flags) { };
        function ___syscall_openat(dirfd, path, flags, varargs) { SYSCALLS.varargs = varargs; }
        var ___syscall_stat64 = function (path, buf) { };
        var __abort_js = function () { abort(""); };
        var nowIsMonotonic = 1;
        var __emscripten_get_now_is_monotonic = function () { return nowIsMonotonic; };
        var __emscripten_memcpy_js = function (dest, src, num) { return HEAPU8.copyWithin(dest, src, src + num); };
        var convertI32PairToI53Checked = function (lo, hi) { return hi + 2097152 >>> 0 < 4194305 - !!lo ? (lo >>> 0) + hi * 4294967296 : NaN; };
        function __mmap_js(len, prot, flags, fd, offset_low, offset_high, allocated, addr) { var offset = convertI32PairToI53Checked(offset_low, offset_high); return -52; }
        function __munmap_js(addr, len, prot, flags, fd, offset_low, offset_high) { var offset = convertI32PairToI53Checked(offset_low, offset_high); }
        var stringToUTF8Array = function (str, heap, outIdx, maxBytesToWrite) { if (!(maxBytesToWrite > 0))
            return 0; var startIdx = outIdx; var endIdx = outIdx + maxBytesToWrite - 1; for (var i = 0; i < str.length; ++i) {
            var u = str.charCodeAt(i);
            if (u >= 55296 && u <= 57343) {
                var u1 = str.charCodeAt(++i);
                u = 65536 + ((u & 1023) << 10) | u1 & 1023;
            }
            if (u <= 127) {
                if (outIdx >= endIdx)
                    break;
                heap[outIdx++] = u;
            }
            else if (u <= 2047) {
                if (outIdx + 1 >= endIdx)
                    break;
                heap[outIdx++] = 192 | u >> 6;
                heap[outIdx++] = 128 | u & 63;
            }
            else if (u <= 65535) {
                if (outIdx + 2 >= endIdx)
                    break;
                heap[outIdx++] = 224 | u >> 12;
                heap[outIdx++] = 128 | u >> 6 & 63;
                heap[outIdx++] = 128 | u & 63;
            }
            else {
                if (outIdx + 3 >= endIdx)
                    break;
                heap[outIdx++] = 240 | u >> 18;
                heap[outIdx++] = 128 | u >> 12 & 63;
                heap[outIdx++] = 128 | u >> 6 & 63;
                heap[outIdx++] = 128 | u & 63;
            }
        } heap[outIdx] = 0; return outIdx - startIdx; };
        var stringToUTF8 = function (str, outPtr, maxBytesToWrite) { return stringToUTF8Array(str, HEAPU8, outPtr, maxBytesToWrite); };
        var __tzset_js = function (timezone, daylight, std_name, dst_name) { var currentYear = (new Date).getFullYear(); var winter = new Date(currentYear, 0, 1); var summer = new Date(currentYear, 6, 1); var winterOffset = winter.getTimezoneOffset(); var summerOffset = summer.getTimezoneOffset(); var stdTimezoneOffset = Math.max(winterOffset, summerOffset); HEAPU32[timezone >> 2] = stdTimezoneOffset * 60; HEAP32[daylight >> 2] = Number(winterOffset != summerOffset); var extractZone = function (timezoneOffset) { var sign = timezoneOffset >= 0 ? "-" : "+"; var absOffset = Math.abs(timezoneOffset); var hours = String(Math.floor(absOffset / 60)).padStart(2, "0"); var minutes = String(absOffset % 60).padStart(2, "0"); return "UTC".concat(sign).concat(hours).concat(minutes); }; var winterName = extractZone(winterOffset); var summerName = extractZone(summerOffset); if (summerOffset < winterOffset) {
            stringToUTF8(winterName, std_name, 17);
            stringToUTF8(summerName, dst_name, 17);
        }
        else {
            stringToUTF8(winterName, dst_name, 17);
            stringToUTF8(summerName, std_name, 17);
        } };
        var readEmAsmArgsArray = [];
        var readEmAsmArgs = function (sigPtr, buf) { readEmAsmArgsArray.length = 0; var ch; while (ch = HEAPU8[sigPtr++]) {
            var wide = ch != 105;
            wide &= ch != 112;
            buf += wide && buf % 8 ? 4 : 0;
            readEmAsmArgsArray.push(ch == 112 ? HEAPU32[buf >> 2] : ch == 105 ? HEAP32[buf >> 2] : HEAPF64[buf >> 3]);
            buf += wide ? 8 : 4;
        } return readEmAsmArgsArray; };
        var runEmAsmFunction = function (code, sigPtr, argbuf) { var args = readEmAsmArgs(sigPtr, argbuf); return ASM_CONSTS[code].apply(ASM_CONSTS, args); };
        var _emscripten_asm_const_int = function (code, sigPtr, argbuf) { return runEmAsmFunction(code, sigPtr, argbuf); };
        var _emscripten_set_main_loop_timing = function (mode, value) { Browser.mainLoop.timingMode = mode; Browser.mainLoop.timingValue = value; if (!Browser.mainLoop.func) {
            return 1;
        } if (!Browser.mainLoop.running) {
            Browser.mainLoop.running = true;
        } if (mode == 0) {
            Browser.mainLoop.scheduler = function Browser_mainLoop_scheduler_setTimeout() { var timeUntilNextTick = Math.max(0, Browser.mainLoop.tickStartTime + value - _emscripten_get_now()) | 0; setTimeout(Browser.mainLoop.runner, timeUntilNextTick); };
            Browser.mainLoop.method = "timeout";
        }
        else if (mode == 1) {
            Browser.mainLoop.scheduler = function Browser_mainLoop_scheduler_rAF() { Browser.requestAnimationFrame(Browser.mainLoop.runner); };
            Browser.mainLoop.method = "rAF";
        }
        else if (mode == 2) {
            if (typeof Browser.setImmediate == "undefined") {
                if (typeof setImmediate == "undefined") {
                    var setImmediates = [];
                    var emscriptenMainLoopMessageId = "setimmediate";
                    var Browser_setImmediate_messageHandler = function (event) { if (event.data === emscriptenMainLoopMessageId || event.data.target === emscriptenMainLoopMessageId) {
                        event.stopPropagation();
                        setImmediates.shift()();
                    } };
                    addEventListener("message", Browser_setImmediate_messageHandler, true);
                    Browser.setImmediate = function Browser_emulated_setImmediate(func) { var _a; setImmediates.push(func); if (ENVIRONMENT_IS_WORKER) {
                        (_a = Module["setImmediates"]) !== null && _a !== void 0 ? _a : (Module["setImmediates"] = []);
                        Module["setImmediates"].push(func);
                        postMessage({ target: emscriptenMainLoopMessageId });
                    }
                    else
                        postMessage(emscriptenMainLoopMessageId, "*"); };
                }
                else {
                    Browser.setImmediate = setImmediate;
                }
            }
            Browser.mainLoop.scheduler = function Browser_mainLoop_scheduler_setImmediate() { Browser.setImmediate(Browser.mainLoop.runner); };
            Browser.mainLoop.method = "immediate";
        } return 0; };
        var _emscripten_get_now;
        _emscripten_get_now = function () { return performance.now(); };
        var setMainLoop = function (browserIterationFunc, fps, simulateInfiniteLoop, arg, noSetTiming) { Browser.mainLoop.func = browserIterationFunc; Browser.mainLoop.arg = arg; var thisMainLoopId = Browser.mainLoop.currentlyRunningMainloop; function checkIsRunning() { if (thisMainLoopId < Browser.mainLoop.currentlyRunningMainloop) {
            return false;
        } return true; } Browser.mainLoop.running = false; Browser.mainLoop.runner = function Browser_mainLoop_runner() { var _a, _b; if (ABORT)
            return; if (Browser.mainLoop.queue.length > 0) {
            var start = Date.now();
            var blocker = Browser.mainLoop.queue.shift();
            blocker.func(blocker.arg);
            if (Browser.mainLoop.remainingBlockers) {
                var remaining = Browser.mainLoop.remainingBlockers;
                var next = remaining % 1 == 0 ? remaining - 1 : Math.floor(remaining);
                if (blocker.counted) {
                    Browser.mainLoop.remainingBlockers = next;
                }
                else {
                    next = next + .5;
                    Browser.mainLoop.remainingBlockers = (8 * remaining + next) / 9;
                }
            }
            Browser.mainLoop.updateStatus();
            if (!checkIsRunning())
                return;
            setTimeout(Browser.mainLoop.runner, 0);
            return;
        } if (!checkIsRunning())
            return; Browser.mainLoop.currentFrameNumber = Browser.mainLoop.currentFrameNumber + 1 | 0; if (Browser.mainLoop.timingMode == 1 && Browser.mainLoop.timingValue > 1 && Browser.mainLoop.currentFrameNumber % Browser.mainLoop.timingValue != 0) {
            Browser.mainLoop.scheduler();
            return;
        }
        else if (Browser.mainLoop.timingMode == 0) {
            Browser.mainLoop.tickStartTime = _emscripten_get_now();
        } Browser.mainLoop.runIter(browserIterationFunc); if (!checkIsRunning())
            return; if (typeof SDL == "object")
            (_b = (_a = SDL.audio) === null || _a === void 0 ? void 0 : _a.queueNewAudioData) === null || _b === void 0 ? void 0 : _b.call(_a); Browser.mainLoop.scheduler(); }; if (!noSetTiming) {
            if (fps && fps > 0) {
                _emscripten_set_main_loop_timing(0, 1e3 / fps);
            }
            else {
                _emscripten_set_main_loop_timing(1, 1);
            }
            Browser.mainLoop.scheduler();
        } if (simulateInfiniteLoop) {
            throw "unwind";
        } };
        var handleException = function (e) { if (e instanceof ExitStatus || e == "unwind") {
            return EXITSTATUS;
        } quit_(1, e); };
        var runtimeKeepaliveCounter = 0;
        var keepRuntimeAlive = function () { return noExitRuntime || runtimeKeepaliveCounter > 0; };
        var _proc_exit = function (code) { var _a; EXITSTATUS = code; if (!keepRuntimeAlive()) {
            (_a = Module["onExit"]) === null || _a === void 0 ? void 0 : _a.call(Module, code);
            ABORT = true;
        } quit_(code, new ExitStatus(code)); };
        var exitJS = function (status, implicit) { EXITSTATUS = status; _proc_exit(status); };
        var _exit = exitJS;
        var maybeExit = function () { if (!keepRuntimeAlive()) {
            try {
                _exit(EXITSTATUS);
            }
            catch (e) {
                handleException(e);
            }
        } };
        var callUserCallback = function (func) { if (ABORT) {
            return;
        } try {
            func();
            maybeExit();
        }
        catch (e) {
            handleException(e);
        } };
        var safeSetTimeout = function (func, timeout) { return setTimeout(function () { callUserCallback(func); }, timeout); };
        var warnOnce = function (text) { warnOnce.shown || (warnOnce.shown = {}); if (!warnOnce.shown[text]) {
            warnOnce.shown[text] = 1;
            err(text);
        } };
        var Browser = { mainLoop: { running: false, scheduler: null, method: "", currentlyRunningMainloop: 0, func: null, arg: 0, timingMode: 0, timingValue: 0, currentFrameNumber: 0, queue: [], pause: function () { Browser.mainLoop.scheduler = null; Browser.mainLoop.currentlyRunningMainloop++; }, resume: function () { Browser.mainLoop.currentlyRunningMainloop++; var timingMode = Browser.mainLoop.timingMode; var timingValue = Browser.mainLoop.timingValue; var func = Browser.mainLoop.func; Browser.mainLoop.func = null; setMainLoop(func, 0, false, Browser.mainLoop.arg, true); _emscripten_set_main_loop_timing(timingMode, timingValue); Browser.mainLoop.scheduler(); }, updateStatus: function () { if (Module["setStatus"]) {
                    var message = Module["statusMessage"] || "Please wait...";
                    var remaining = Browser.mainLoop.remainingBlockers;
                    var expected = Browser.mainLoop.expectedBlockers;
                    if (remaining) {
                        if (remaining < expected) {
                            Module["setStatus"]("{message} ({expected - remaining}/{expected})");
                        }
                        else {
                            Module["setStatus"](message);
                        }
                    }
                    else {
                        Module["setStatus"]("");
                    }
                } }, runIter: function (func) { var _a; if (ABORT)
                    return; if (Module["preMainLoop"]) {
                    var preRet = Module["preMainLoop"]();
                    if (preRet === false) {
                        return;
                    }
                } callUserCallback(func); (_a = Module["postMainLoop"]) === null || _a === void 0 ? void 0 : _a.call(Module); } }, isFullscreen: false, pointerLock: false, moduleContextCreatedCallbacks: [], workers: [], init: function () { if (Browser.initted)
                return; Browser.initted = true; function pointerLockChange() { Browser.pointerLock = document["pointerLockElement"] === Module["canvas"] || document["mozPointerLockElement"] === Module["canvas"] || document["webkitPointerLockElement"] === Module["canvas"] || document["msPointerLockElement"] === Module["canvas"]; } var canvas = Module["canvas"]; if (canvas) {
                canvas.requestPointerLock = canvas["requestPointerLock"] || canvas["mozRequestPointerLock"] || canvas["webkitRequestPointerLock"] || canvas["msRequestPointerLock"] || (function () { });
                canvas.exitPointerLock = document["exitPointerLock"] || document["mozExitPointerLock"] || document["webkitExitPointerLock"] || document["msExitPointerLock"] || (function () { });
                canvas.exitPointerLock = canvas.exitPointerLock.bind(document);
                document.addEventListener("pointerlockchange", pointerLockChange, false);
                document.addEventListener("mozpointerlockchange", pointerLockChange, false);
                document.addEventListener("webkitpointerlockchange", pointerLockChange, false);
                document.addEventListener("mspointerlockchange", pointerLockChange, false);
                if (Module["elementPointerLock"]) {
                    canvas.addEventListener("click", function (ev) { if (!Browser.pointerLock && Module["canvas"].requestPointerLock) {
                        Module["canvas"].requestPointerLock();
                        ev.preventDefault();
                    } }, false);
                }
            } }, createContext: function (canvas, useWebGL, setInModule, webGLContextAttributes) { if (useWebGL && Module.ctx && canvas == Module.canvas)
                return Module.ctx; var ctx; var contextHandle; if (useWebGL) {
                var contextAttributes = { antialias: false, alpha: false, majorVersion: 1 };
                if (webGLContextAttributes) {
                    for (var attribute in webGLContextAttributes) {
                        contextAttributes[attribute] = webGLContextAttributes[attribute];
                    }
                }
                if (typeof GL != "undefined") {
                    contextHandle = GL.createContext(canvas, contextAttributes);
                    if (contextHandle) {
                        ctx = GL.getContext(contextHandle).GLctx;
                    }
                }
            }
            else {
                ctx = canvas.getContext("2d");
            } if (!ctx)
                return null; if (setInModule) {
                Module.ctx = ctx;
                if (useWebGL)
                    GL.makeContextCurrent(contextHandle);
                Module.useWebGL = useWebGL;
                Browser.moduleContextCreatedCallbacks.forEach(function (callback) { return callback(); });
                Browser.init();
            } return ctx; }, destroyContext: function (canvas, useWebGL, setInModule) { }, fullscreenHandlersInstalled: false, lockPointer: undefined, resizeCanvas: undefined, requestFullscreen: function (lockPointer, resizeCanvas) { Browser.lockPointer = lockPointer; Browser.resizeCanvas = resizeCanvas; if (typeof Browser.lockPointer == "undefined")
                Browser.lockPointer = true; if (typeof Browser.resizeCanvas == "undefined")
                Browser.resizeCanvas = false; var canvas = Module["canvas"]; function fullscreenChange() { var _a, _b; Browser.isFullscreen = false; var canvasContainer = canvas.parentNode; if ((document["fullscreenElement"] || document["mozFullScreenElement"] || document["msFullscreenElement"] || document["webkitFullscreenElement"] || document["webkitCurrentFullScreenElement"]) === canvasContainer) {
                canvas.exitFullscreen = Browser.exitFullscreen;
                if (Browser.lockPointer)
                    canvas.requestPointerLock();
                Browser.isFullscreen = true;
                if (Browser.resizeCanvas) {
                    Browser.setFullscreenCanvasSize();
                }
                else {
                    Browser.updateCanvasDimensions(canvas);
                }
            }
            else {
                canvasContainer.parentNode.insertBefore(canvas, canvasContainer);
                canvasContainer.parentNode.removeChild(canvasContainer);
                if (Browser.resizeCanvas) {
                    Browser.setWindowedCanvasSize();
                }
                else {
                    Browser.updateCanvasDimensions(canvas);
                }
            } (_a = Module["onFullScreen"]) === null || _a === void 0 ? void 0 : _a.call(Module, Browser.isFullscreen); (_b = Module["onFullscreen"]) === null || _b === void 0 ? void 0 : _b.call(Module, Browser.isFullscreen); } if (!Browser.fullscreenHandlersInstalled) {
                Browser.fullscreenHandlersInstalled = true;
                document.addEventListener("fullscreenchange", fullscreenChange, false);
                document.addEventListener("mozfullscreenchange", fullscreenChange, false);
                document.addEventListener("webkitfullscreenchange", fullscreenChange, false);
                document.addEventListener("MSFullscreenChange", fullscreenChange, false);
            } var canvasContainer = document.createElement("div"); canvas.parentNode.insertBefore(canvasContainer, canvas); canvasContainer.appendChild(canvas); canvasContainer.requestFullscreen = canvasContainer["requestFullscreen"] || canvasContainer["mozRequestFullScreen"] || canvasContainer["msRequestFullscreen"] || (canvasContainer["webkitRequestFullscreen"] ? function () { return canvasContainer["webkitRequestFullscreen"](Element["ALLOW_KEYBOARD_INPUT"]); } : null) || (canvasContainer["webkitRequestFullScreen"] ? function () { return canvasContainer["webkitRequestFullScreen"](Element["ALLOW_KEYBOARD_INPUT"]); } : null); canvasContainer.requestFullscreen(); }, exitFullscreen: function () { if (!Browser.isFullscreen) {
                return false;
            } var CFS = document["exitFullscreen"] || document["cancelFullScreen"] || document["mozCancelFullScreen"] || document["msExitFullscreen"] || document["webkitCancelFullScreen"] || (function () { }); CFS.apply(document, []); return true; }, nextRAF: 0, fakeRequestAnimationFrame: function (func) { var now = Date.now(); if (Browser.nextRAF === 0) {
                Browser.nextRAF = now + 1e3 / 60;
            }
            else {
                while (now + 2 >= Browser.nextRAF) {
                    Browser.nextRAF += 1e3 / 60;
                }
            } var delay = Math.max(Browser.nextRAF - now, 0); setTimeout(func, delay); }, requestAnimationFrame: function (func) { if (typeof requestAnimationFrame == "function") {
                requestAnimationFrame(func);
                return;
            } var RAF = Browser.fakeRequestAnimationFrame; RAF(func); }, safeSetTimeout: function (func, timeout) { return safeSetTimeout(func, timeout); }, safeRequestAnimationFrame: function (func) { return Browser.requestAnimationFrame(function () { callUserCallback(func); }); }, getMimetype: function (name) { return { jpg: "image/jpeg", jpeg: "image/jpeg", png: "image/png", bmp: "image/bmp", ogg: "audio/ogg", wav: "audio/wav", mp3: "audio/mpeg" }[name.substr(name.lastIndexOf(".") + 1)]; }, getUserMedia: function (func) { window.getUserMedia || (window.getUserMedia = navigator["getUserMedia"] || navigator["mozGetUserMedia"]); window.getUserMedia(func); }, getMovementX: function (event) { return event["movementX"] || event["mozMovementX"] || event["webkitMovementX"] || 0; }, getMovementY: function (event) { return event["movementY"] || event["mozMovementY"] || event["webkitMovementY"] || 0; }, getMouseWheelDelta: function (event) { var delta = 0; switch (event.type) {
                case "DOMMouseScroll":
                    delta = event.detail / 3;
                    break;
                case "mousewheel":
                    delta = event.wheelDelta / 120;
                    break;
                case "wheel":
                    delta = event.deltaY;
                    switch (event.deltaMode) {
                        case 0:
                            delta /= 100;
                            break;
                        case 1:
                            delta /= 3;
                            break;
                        case 2:
                            delta *= 80;
                            break;
                        default: throw "unrecognized mouse wheel delta mode: " + event.deltaMode;
                    }
                    break;
                default: throw "unrecognized mouse wheel event: " + event.type;
            } return delta; }, mouseX: 0, mouseY: 0, mouseMovementX: 0, mouseMovementY: 0, touches: {}, lastTouches: {}, calculateMouseCoords: function (pageX, pageY) { var rect = Module["canvas"].getBoundingClientRect(); var cw = Module["canvas"].width; var ch = Module["canvas"].height; var scrollX = typeof window.scrollX != "undefined" ? window.scrollX : window.pageXOffset; var scrollY = typeof window.scrollY != "undefined" ? window.scrollY : window.pageYOffset; var adjustedX = pageX - (scrollX + rect.left); var adjustedY = pageY - (scrollY + rect.top); adjustedX = adjustedX * (cw / rect.width); adjustedY = adjustedY * (ch / rect.height); return { x: adjustedX, y: adjustedY }; }, setMouseCoords: function (pageX, pageY) { var _a = Browser.calculateMouseCoords(pageX, pageY), x = _a.x, y = _a.y; Browser.mouseMovementX = x - Browser.mouseX; Browser.mouseMovementY = y - Browser.mouseY; Browser.mouseX = x; Browser.mouseY = y; }, calculateMouseEvent: function (event) { if (Browser.pointerLock) {
                if (event.type != "mousemove" && "mozMovementX" in event) {
                    Browser.mouseMovementX = Browser.mouseMovementY = 0;
                }
                else {
                    Browser.mouseMovementX = Browser.getMovementX(event);
                    Browser.mouseMovementY = Browser.getMovementY(event);
                }
                Browser.mouseX += Browser.mouseMovementX;
                Browser.mouseY += Browser.mouseMovementY;
            }
            else {
                if (event.type === "touchstart" || event.type === "touchend" || event.type === "touchmove") {
                    var touch = event.touch;
                    if (touch === undefined) {
                        return;
                    }
                    var coords = Browser.calculateMouseCoords(touch.pageX, touch.pageY);
                    if (event.type === "touchstart") {
                        Browser.lastTouches[touch.identifier] = coords;
                        Browser.touches[touch.identifier] = coords;
                    }
                    else if (event.type === "touchend" || event.type === "touchmove") {
                        var last = Browser.touches[touch.identifier];
                        last || (last = coords);
                        Browser.lastTouches[touch.identifier] = last;
                        Browser.touches[touch.identifier] = coords;
                    }
                    return;
                }
                Browser.setMouseCoords(event.pageX, event.pageY);
            } }, resizeListeners: [], updateResizeListeners: function () { var canvas = Module["canvas"]; Browser.resizeListeners.forEach(function (listener) { return listener(canvas.width, canvas.height); }); }, setCanvasSize: function (width, height, noUpdates) { var canvas = Module["canvas"]; Browser.updateCanvasDimensions(canvas, width, height); if (!noUpdates)
                Browser.updateResizeListeners(); }, windowedWidth: 0, windowedHeight: 0, setFullscreenCanvasSize: function () { if (typeof SDL != "undefined") {
                var flags = HEAPU32[SDL.screen >> 2];
                flags = flags | 8388608;
                HEAP32[SDL.screen >> 2] = flags;
            } Browser.updateCanvasDimensions(Module["canvas"]); Browser.updateResizeListeners(); }, setWindowedCanvasSize: function () { if (typeof SDL != "undefined") {
                var flags = HEAPU32[SDL.screen >> 2];
                flags = flags & ~8388608;
                HEAP32[SDL.screen >> 2] = flags;
            } Browser.updateCanvasDimensions(Module["canvas"]); Browser.updateResizeListeners(); }, updateCanvasDimensions: function (canvas, wNative, hNative) { if (wNative && hNative) {
                canvas.widthNative = wNative;
                canvas.heightNative = hNative;
            }
            else {
                wNative = canvas.widthNative;
                hNative = canvas.heightNative;
            } var w = wNative; var h = hNative; if (Module["forcedAspectRatio"] && Module["forcedAspectRatio"] > 0) {
                if (w / h < Module["forcedAspectRatio"]) {
                    w = Math.round(h * Module["forcedAspectRatio"]);
                }
                else {
                    h = Math.round(w / Module["forcedAspectRatio"]);
                }
            } if ((document["fullscreenElement"] || document["mozFullScreenElement"] || document["msFullscreenElement"] || document["webkitFullscreenElement"] || document["webkitCurrentFullScreenElement"]) === canvas.parentNode && typeof screen != "undefined") {
                var factor = Math.min(screen.width / w, screen.height / h);
                w = Math.round(w * factor);
                h = Math.round(h * factor);
            } if (Browser.resizeCanvas) {
                if (canvas.width != w)
                    canvas.width = w;
                if (canvas.height != h)
                    canvas.height = h;
                if (typeof canvas.style != "undefined") {
                    canvas.style.removeProperty("width");
                    canvas.style.removeProperty("height");
                }
            }
            else {
                if (canvas.width != wNative)
                    canvas.width = wNative;
                if (canvas.height != hNative)
                    canvas.height = hNative;
                if (typeof canvas.style != "undefined") {
                    if (w != wNative || h != hNative) {
                        canvas.style.setProperty("width", w + "px", "important");
                        canvas.style.setProperty("height", h + "px", "important");
                    }
                    else {
                        canvas.style.removeProperty("width");
                        canvas.style.removeProperty("height");
                    }
                }
            } } };
        var wasmTableMirror = [];
        var wasmTable;
        var getWasmTableEntry = function (funcPtr) { var func = wasmTableMirror[funcPtr]; if (!func) {
            if (funcPtr >= wasmTableMirror.length)
                wasmTableMirror.length = funcPtr + 1;
            wasmTableMirror[funcPtr] = func = wasmTable.get(funcPtr);
        } return func; };
        var _emscripten_call_worker = function (id, funcName, data, size, callback, arg) { funcName = UTF8ToString(funcName); var info = Browser.workers[id]; var callbackId = -1; if (callback) {
            callbackId = info.callbacks.length;
            info.callbacks.push({ func: getWasmTableEntry(callback), arg: arg });
            info.awaited++;
        } var transferObject = { funcName: funcName, callbackId: callbackId, data: data ? new Uint8Array(HEAPU8.subarray(data, data + size)) : 0 }; if (data) {
            info.worker.postMessage(transferObject, [transferObject.data.buffer]);
        }
        else {
            info.worker.postMessage(transferObject);
        } };
        var _emscripten_create_worker = function (url) { url = UTF8ToString(url); var id = Browser.workers.length; var info = { worker: new Worker(url), callbacks: [], awaited: 0, buffer: 0, bufferSize: 0 }; info.worker.onmessage = function info_worker_onmessage(msg) { if (ABORT)
            return; var info = Browser.workers[id]; if (!info)
            return; var callbackId = msg.data["callbackId"]; var callbackInfo = info.callbacks[callbackId]; if (!callbackInfo)
            return; if (msg.data["finalResponse"]) {
            info.awaited--;
            info.callbacks[callbackId] = null;
        } var data = msg.data["data"]; if (data) {
            if (!data.byteLength)
                data = new Uint8Array(data);
            if (!info.buffer || info.bufferSize < data.length) {
                if (info.buffer)
                    _free(info.buffer);
                info.bufferSize = data.length;
                info.buffer = _malloc(data.length);
            }
            HEAPU8.set(data, info.buffer);
            callbackInfo.func(info.buffer, data.length, callbackInfo.arg);
        }
        else {
            callbackInfo.func(0, 0, callbackInfo.arg);
        } }; Browser.workers.push(info); return id; };
        var _emscripten_date_now = function () { return Date.now(); };
        var getHeapMax = function () { return 2147483648; };
        var _emscripten_get_heap_max = function () { return getHeapMax(); };
        var growMemory = function (size) { var b = wasmMemory.buffer; var pages = (size - b.byteLength + 65535) / 65536; try {
            wasmMemory.grow(pages);
            updateMemoryViews();
            return 1;
        }
        catch (e) { } };
        var _emscripten_resize_heap = function (requestedSize) { var oldSize = HEAPU8.length; requestedSize >>>= 0; var maxHeapSize = getHeapMax(); if (requestedSize > maxHeapSize) {
            return false;
        } var alignUp = function (x, multiple) { return x + (multiple - x % multiple) % multiple; }; for (var cutDown = 1; cutDown <= 4; cutDown *= 2) {
            var overGrownHeapSize = oldSize * (1 + .2 / cutDown);
            overGrownHeapSize = Math.min(overGrownHeapSize, requestedSize + 100663296);
            var newSize = Math.min(maxHeapSize, alignUp(Math.max(requestedSize, overGrownHeapSize), 65536));
            var replacement = growMemory(newSize);
            if (replacement) {
                return true;
            }
        } return false; };
        var ENV = {};
        var getExecutableName = function () { return thisProgram || "./this.program"; };
        var getEnvStrings = function () { if (!getEnvStrings.strings) {
            var lang = (typeof navigator == "object" && navigator.languages && navigator.languages[0] || "C").replace("-", "_") + ".UTF-8";
            var env = { USER: "web_user", LOGNAME: "web_user", PATH: "/", PWD: "/", HOME: "/home/web_user", LANG: lang, _: getExecutableName() };
            for (var x in ENV) {
                if (ENV[x] === undefined)
                    delete env[x];
                else
                    env[x] = ENV[x];
            }
            var strings = [];
            for (var x in env) {
                strings.push("".concat(x, "=").concat(env[x]));
            }
            getEnvStrings.strings = strings;
        } return getEnvStrings.strings; };
        var stringToAscii = function (str, buffer) { for (var i = 0; i < str.length; ++i) {
            HEAP8[buffer++] = str.charCodeAt(i);
        } HEAP8[buffer] = 0; };
        var _environ_get = function (__environ, environ_buf) { var bufSize = 0; getEnvStrings().forEach(function (string, i) { var ptr = environ_buf + bufSize; HEAPU32[__environ + i * 4 >> 2] = ptr; stringToAscii(string, ptr); bufSize += string.length + 1; }); return 0; };
        var _environ_sizes_get = function (penviron_count, penviron_buf_size) { var strings = getEnvStrings(); HEAPU32[penviron_count >> 2] = strings.length; var bufSize = 0; strings.forEach(function (string) { return bufSize += string.length + 1; }); HEAPU32[penviron_buf_size >> 2] = bufSize; return 0; };
        var _fd_close = function (fd) { return 52; };
        var _fd_read = function (fd, iov, iovcnt, pnum) { return 52; };
        function _fd_seek(fd, offset_low, offset_high, whence, newOffset) { var offset = convertI32PairToI53Checked(offset_low, offset_high); return 70; }
        var printCharBuffers = [null, [], []];
        var printChar = function (stream, curr) { var buffer = printCharBuffers[stream]; if (curr === 0 || curr === 10) {
            (stream === 1 ? out : err)(UTF8ArrayToString(buffer, 0));
            buffer.length = 0;
        }
        else {
            buffer.push(curr);
        } };
        var _fd_write = function (fd, iov, iovcnt, pnum) { var num = 0; for (var i = 0; i < iovcnt; i++) {
            var ptr = HEAPU32[iov >> 2];
            var len = HEAPU32[iov + 4 >> 2];
            iov += 8;
            for (var j = 0; j < len; j++) {
                printChar(fd, HEAPU8[ptr + j]);
            }
            num += len;
        } HEAPU32[pnum >> 2] = num; return 0; };
        var initRandomFill = function () { if (typeof crypto == "object" && typeof crypto["getRandomValues"] == "function") {
            return function (view) { return crypto.getRandomValues(view); };
        }
        else
            abort("initRandomDevice"); };
        var randomFill = function (view) { return (randomFill = initRandomFill())(view); };
        var _getentropy = function (buffer, size) { randomFill(HEAPU8.subarray(buffer, buffer + size)); return 0; };
        var _llvm_eh_typeid_for = function (type) { return type; };
        var uleb128Encode = function (n, target) { if (n < 128) {
            target.push(n);
        }
        else {
            target.push(n % 128 | 128, n >> 7);
        } };
        var sigToWasmTypes = function (sig) { var typeNames = { i: "i32", j: "i64", f: "f32", d: "f64", e: "externref", p: "i32" }; var type = { parameters: [], results: sig[0] == "v" ? [] : [typeNames[sig[0]]] }; for (var i = 1; i < sig.length; ++i) {
            type.parameters.push(typeNames[sig[i]]);
        } return type; };
        var generateFuncType = function (sig, target) { var sigRet = sig.slice(0, 1); var sigParam = sig.slice(1); var typeCodes = { i: 127, p: 127, j: 126, f: 125, d: 124, e: 111 }; target.push(96); uleb128Encode(sigParam.length, target); for (var i = 0; i < sigParam.length; ++i) {
            target.push(typeCodes[sigParam[i]]);
        } if (sigRet == "v") {
            target.push(0);
        }
        else {
            target.push(1, typeCodes[sigRet]);
        } };
        var convertJsFunctionToWasm = function (func, sig) { if (typeof WebAssembly.Function == "function") {
            return new WebAssembly.Function(sigToWasmTypes(sig), func);
        } var typeSectionBody = [1]; generateFuncType(sig, typeSectionBody); var bytes = [0, 97, 115, 109, 1, 0, 0, 0, 1]; uleb128Encode(typeSectionBody.length, bytes); bytes.push.apply(bytes, typeSectionBody); bytes.push(2, 7, 1, 1, 101, 1, 102, 0, 0, 7, 5, 1, 1, 102, 0, 0); var module = new WebAssembly.Module(new Uint8Array(bytes)); var instance = new WebAssembly.Instance(module, { e: { f: func } }); var wrappedFunc = instance.exports["f"]; return wrappedFunc; };
        var updateTableMap = function (offset, count) { if (functionsInTableMap) {
            for (var i = offset; i < offset + count; i++) {
                var item = getWasmTableEntry(i);
                if (item) {
                    functionsInTableMap.set(item, i);
                }
            }
        } };
        var functionsInTableMap;
        var getFunctionAddress = function (func) { if (!functionsInTableMap) {
            functionsInTableMap = new WeakMap;
            updateTableMap(0, wasmTable.length);
        } return functionsInTableMap.get(func) || 0; };
        var freeTableIndexes = [];
        var getEmptyTableSlot = function () { if (freeTableIndexes.length) {
            return freeTableIndexes.pop();
        } try {
            wasmTable.grow(1);
        }
        catch (err) {
            if (!(err instanceof RangeError)) {
                throw err;
            }
            throw "Unable to grow wasm table. Set ALLOW_TABLE_GROWTH.";
        } return wasmTable.length - 1; };
        var setWasmTableEntry = function (idx, func) { wasmTable.set(idx, func); wasmTableMirror[idx] = wasmTable.get(idx); };
        var addFunction = function (func, sig) { var rtn = getFunctionAddress(func); if (rtn) {
            return rtn;
        } var ret = getEmptyTableSlot(); try {
            setWasmTableEntry(ret, func);
        }
        catch (err) {
            if (!(err instanceof TypeError)) {
                throw err;
            }
            var wrapped = convertJsFunctionToWasm(func, sig);
            setWasmTableEntry(ret, wrapped);
        } functionsInTableMap.set(func, ret); return ret; };
        Module["requestFullscreen"] = Browser.requestFullscreen;
        Module["requestAnimationFrame"] = Browser.requestAnimationFrame;
        Module["setCanvasSize"] = Browser.setCanvasSize;
        Module["pauseMainLoop"] = Browser.mainLoop.pause;
        Module["resumeMainLoop"] = Browser.mainLoop.resume;
        Module["getUserMedia"] = Browser.getUserMedia;
        Module["createContext"] = Browser.createContext;
        var wasmImports = { __cxa_begin_catch: ___cxa_begin_catch, __cxa_end_catch: ___cxa_end_catch, __cxa_find_matching_catch_2: ___cxa_find_matching_catch_2, __cxa_find_matching_catch_3: ___cxa_find_matching_catch_3, __cxa_find_matching_catch_4: ___cxa_find_matching_catch_4, __cxa_throw: ___cxa_throw, __resumeException: ___resumeException, __syscall_dup: ___syscall_dup, __syscall_faccessat: ___syscall_faccessat, __syscall_fcntl64: ___syscall_fcntl64, __syscall_fstat64: ___syscall_fstat64, __syscall_ioctl: ___syscall_ioctl, __syscall_lstat64: ___syscall_lstat64, __syscall_newfstatat: ___syscall_newfstatat, __syscall_openat: ___syscall_openat, __syscall_stat64: ___syscall_stat64, _abort_js: __abort_js, _emscripten_get_now_is_monotonic: __emscripten_get_now_is_monotonic, _emscripten_memcpy_js: __emscripten_memcpy_js, _mmap_js: __mmap_js, _munmap_js: __munmap_js, _tzset_js: __tzset_js, emscripten_asm_const_int: _emscripten_asm_const_int, emscripten_call_worker: _emscripten_call_worker, emscripten_create_worker: _emscripten_create_worker, emscripten_date_now: _emscripten_date_now, emscripten_get_heap_max: _emscripten_get_heap_max, emscripten_get_now: _emscripten_get_now, emscripten_resize_heap: _emscripten_resize_heap, environ_get: _environ_get, environ_sizes_get: _environ_sizes_get, exit: _exit, fd_close: _fd_close, fd_read: _fd_read, fd_seek: _fd_seek, fd_write: _fd_write, getentropy: _getentropy, invoke_i: invoke_i, invoke_ii: invoke_ii, invoke_iidddddi: invoke_iidddddi, invoke_iii: invoke_iii, invoke_iiiddddd: invoke_iiiddddd, invoke_iiii: invoke_iiii, invoke_iiiiii: invoke_iiiiii, invoke_iiiiiii: invoke_iiiiiii, invoke_iiijii: invoke_iiijii, invoke_v: invoke_v, invoke_vi: invoke_vi, invoke_vii: invoke_vii, invoke_viiidi: invoke_viiidi, invoke_viiiiii: invoke_viiiiii, llvm_eh_typeid_for: _llvm_eh_typeid_for };
        var wasmExports = createWasm();
        var ___wasm_call_ctors = function () { return (___wasm_call_ctors = wasmExports["__wasm_call_ctors"])(); };
        var _init_tracker = Module["_init_tracker"] = function (a0, a1, a2, a3, a4) { return (_init_tracker = Module["_init_tracker"] = wasmExports["init_tracker"])(a0, a1, a2, a3, a4); };
        var _init_face_detector = Module["_init_face_detector"] = function (a0) { return (_init_face_detector = Module["_init_face_detector"] = wasmExports["init_face_detector"])(a0); };
        var _get_image_offset = Module["_get_image_offset"] = function (a0, a1) { return (_get_image_offset = Module["_get_image_offset"] = wasmExports["get_image_offset"])(a0, a1); };
        var _detect_landmarks = Module["_detect_landmarks"] = function (a0, a1) { return (_detect_landmarks = Module["_detect_landmarks"] = wasmExports["detect_landmarks"])(a0, a1); };
        var _get_landmarks_offset = Module["_get_landmarks_offset"] = function (a0) { return (_get_landmarks_offset = Module["_get_landmarks_offset"] = wasmExports["get_landmarks_offset"])(a0); };
        var _add_training = Module["_add_training"] = function (a0, a1, a2, a3, a4, a5, a6, a7) { return (_add_training = Module["_add_training"] = wasmExports["add_training"])(a0, a1, a2, a3, a4, a5, a6, a7); };
        var _train = Module["_train"] = function (a0, a1, a2) { return (_train = Module["_train"] = wasmExports["train"])(a0, a1, a2); };
        var _reset_training = Module["_reset_training"] = function () { return (_reset_training = Module["_reset_training"] = wasmExports["reset_training"])(); };
        var _update = Module["_update"] = function (a0, a1, a2, a3, a4, a5) { return (_update = Module["_update"] = wasmExports["update"])(a0, a1, a2, a3, a4, a5); };
        var _get_x = Module["_get_x"] = function () { return (_get_x = Module["_get_x"] = wasmExports["get_x"])(); };
        var _get_y = Module["_get_y"] = function () { return (_get_y = Module["_get_y"] = wasmExports["get_y"])(); };
        var _get_mod_x = Module["_get_mod_x"] = function () { return (_get_mod_x = Module["_get_mod_x"] = wasmExports["get_mod_x"])(); };
        var _get_mod_y = Module["_get_mod_y"] = function () { return (_get_mod_y = Module["_get_mod_y"] = wasmExports["get_mod_y"])(); };
        var _get_head_x = Module["_get_head_x"] = function () { return (_get_head_x = Module["_get_head_x"] = wasmExports["get_head_x"])(); };
        var _get_head_y = Module["_get_head_y"] = function () { return (_get_head_y = Module["_get_head_y"] = wasmExports["get_head_y"])(); };
        var _get_timestamp = Module["_get_timestamp"] = function () { return (_get_timestamp = Module["_get_timestamp"] = wasmExports["get_timestamp"])(); };
        var _get_duration = Module["_get_duration"] = function () { return (_get_duration = Module["_get_duration"] = wasmExports["get_duration"])(); };
        var _get_model = Module["_get_model"] = function (a0, a1) { return (_get_model = Module["_get_model"] = wasmExports["get_model"])(a0, a1); };
        var _set_model = Module["_set_model"] = function (a0, a1, a2, a3, a4) { return (_set_model = Module["_set_model"] = wasmExports["set_model"])(a0, a1, a2, a3, a4); };
        var _get_params = Module["_get_params"] = function (a0, a1, a2, a3) { return (_get_params = Module["_get_params"] = wasmExports["get_params"])(a0, a1, a2, a3); };
        var _set_params = Module["_set_params"] = function (a0, a1) { return (_set_params = Module["_set_params"] = wasmExports["set_params"])(a0, a1); };
        var _free = Module["_free"] = function (a0) { return (_free = Module["_free"] = wasmExports["free"])(a0); };
        var _malloc = Module["_malloc"] = function (a0) { return (_malloc = Module["_malloc"] = wasmExports["malloc"])(a0); };
        var _setThrew = function (a0, a1) { return (_setThrew = wasmExports["setThrew"])(a0, a1); };
        var __emscripten_tempret_set = function (a0) { return (__emscripten_tempret_set = wasmExports["_emscripten_tempret_set"])(a0); };
        var __emscripten_stack_restore = function (a0) { return (__emscripten_stack_restore = wasmExports["_emscripten_stack_restore"])(a0); };
        var __emscripten_stack_alloc = function (a0) { return (__emscripten_stack_alloc = wasmExports["_emscripten_stack_alloc"])(a0); };
        var _emscripten_stack_get_current = function () { return (_emscripten_stack_get_current = wasmExports["emscripten_stack_get_current"])(); };
        var ___cxa_decrement_exception_refcount = function (a0) { return (___cxa_decrement_exception_refcount = wasmExports["__cxa_decrement_exception_refcount"])(a0); };
        var ___cxa_increment_exception_refcount = function (a0) { return (___cxa_increment_exception_refcount = wasmExports["__cxa_increment_exception_refcount"])(a0); };
        var ___cxa_can_catch = function (a0, a1, a2) { return (___cxa_can_catch = wasmExports["__cxa_can_catch"])(a0, a1, a2); };
        var ___cxa_is_pointer_type = function (a0) { return (___cxa_is_pointer_type = wasmExports["__cxa_is_pointer_type"])(a0); };
        var dynCall_ji = Module["dynCall_ji"] = function (a0, a1) { return (dynCall_ji = Module["dynCall_ji"] = wasmExports["dynCall_ji"])(a0, a1); };
        var dynCall_jjj = Module["dynCall_jjj"] = function (a0, a1, a2, a3, a4) { return (dynCall_jjj = Module["dynCall_jjj"] = wasmExports["dynCall_jjj"])(a0, a1, a2, a3, a4); };
        var dynCall_iiiijj = Module["dynCall_iiiijj"] = function (a0, a1, a2, a3, a4, a5, a6, a7) { return (dynCall_iiiijj = Module["dynCall_iiiijj"] = wasmExports["dynCall_iiiijj"])(a0, a1, a2, a3, a4, a5, a6, a7); };
        var dynCall_viijj = Module["dynCall_viijj"] = function (a0, a1, a2, a3, a4, a5, a6) { return (dynCall_viijj = Module["dynCall_viijj"] = wasmExports["dynCall_viijj"])(a0, a1, a2, a3, a4, a5, a6); };
        var dynCall_viiijjj = Module["dynCall_viiijjj"] = function (a0, a1, a2, a3, a4, a5, a6, a7, a8, a9) { return (dynCall_viiijjj = Module["dynCall_viiijjj"] = wasmExports["dynCall_viiijjj"])(a0, a1, a2, a3, a4, a5, a6, a7, a8, a9); };
        var dynCall_iiijii = Module["dynCall_iiijii"] = function (a0, a1, a2, a3, a4, a5, a6) { return (dynCall_iiijii = Module["dynCall_iiijii"] = wasmExports["dynCall_iiijii"])(a0, a1, a2, a3, a4, a5, a6); };
        var dynCall_iiiji = Module["dynCall_iiiji"] = function (a0, a1, a2, a3, a4, a5) { return (dynCall_iiiji = Module["dynCall_iiiji"] = wasmExports["dynCall_iiiji"])(a0, a1, a2, a3, a4, a5); };
        var dynCall_iiij = Module["dynCall_iiij"] = function (a0, a1, a2, a3, a4) { return (dynCall_iiij = Module["dynCall_iiij"] = wasmExports["dynCall_iiij"])(a0, a1, a2, a3, a4); };
        var dynCall_iijii = Module["dynCall_iijii"] = function (a0, a1, a2, a3, a4, a5) { return (dynCall_iijii = Module["dynCall_iijii"] = wasmExports["dynCall_iijii"])(a0, a1, a2, a3, a4, a5); };
        var dynCall_iiijiji = Module["dynCall_iiijiji"] = function (a0, a1, a2, a3, a4, a5, a6, a7, a8) { return (dynCall_iiijiji = Module["dynCall_iiijiji"] = wasmExports["dynCall_iiijiji"])(a0, a1, a2, a3, a4, a5, a6, a7, a8); };
        var dynCall_iijjiiii = Module["dynCall_iijjiiii"] = function (a0, a1, a2, a3, a4, a5, a6, a7, a8, a9) { return (dynCall_iijjiiii = Module["dynCall_iijjiiii"] = wasmExports["dynCall_iijjiiii"])(a0, a1, a2, a3, a4, a5, a6, a7, a8, a9); };
        var dynCall_jiji = Module["dynCall_jiji"] = function (a0, a1, a2, a3, a4) { return (dynCall_jiji = Module["dynCall_jiji"] = wasmExports["dynCall_jiji"])(a0, a1, a2, a3, a4); };
        var dynCall_viijii = Module["dynCall_viijii"] = function (a0, a1, a2, a3, a4, a5, a6) { return (dynCall_viijii = Module["dynCall_viijii"] = wasmExports["dynCall_viijii"])(a0, a1, a2, a3, a4, a5, a6); };
        var dynCall_iiiiij = Module["dynCall_iiiiij"] = function (a0, a1, a2, a3, a4, a5, a6) { return (dynCall_iiiiij = Module["dynCall_iiiiij"] = wasmExports["dynCall_iiiiij"])(a0, a1, a2, a3, a4, a5, a6); };
        var dynCall_iiiiijj = Module["dynCall_iiiiijj"] = function (a0, a1, a2, a3, a4, a5, a6, a7, a8) { return (dynCall_iiiiijj = Module["dynCall_iiiiijj"] = wasmExports["dynCall_iiiiijj"])(a0, a1, a2, a3, a4, a5, a6, a7, a8); };
        var dynCall_iiiiiijj = Module["dynCall_iiiiiijj"] = function (a0, a1, a2, a3, a4, a5, a6, a7, a8, a9) { return (dynCall_iiiiiijj = Module["dynCall_iiiiiijj"] = wasmExports["dynCall_iiiiiijj"])(a0, a1, a2, a3, a4, a5, a6, a7, a8, a9); };
        function invoke_vi(index, a1) { var sp = stackSave(); try {
            getWasmTableEntry(index)(a1);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_ii(index, a1) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iidddddi(index, a1, a2, a3, a4, a5, a6, a7) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iii(index, a1, a2) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_vii(index, a1, a2) { var sp = stackSave(); try {
            getWasmTableEntry(index)(a1, a2);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iiiddddd(index, a1, a2, a3, a4, a5, a6, a7) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iiii(index, a1, a2, a3) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2, a3);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_viiidi(index, a1, a2, a3, a4, a5) { var sp = stackSave(); try {
            getWasmTableEntry(index)(a1, a2, a3, a4, a5);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_viiiiii(index, a1, a2, a3, a4, a5, a6) { var sp = stackSave(); try {
            getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iiiiiii(index, a1, a2, a3, a4, a5, a6) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iiiiii(index, a1, a2, a3, a4, a5) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2, a3, a4, a5);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_v(index) { var sp = stackSave(); try {
            getWasmTableEntry(index)();
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_i(index) { var sp = stackSave(); try {
            return getWasmTableEntry(index)();
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iiijii(index, a1, a2, a3, a4, a5, a6) { var sp = stackSave(); try {
            return dynCall_iiijii(index, a1, a2, a3, a4, a5, a6);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        Module["addFunction"] = addFunction;
        var calledRun;
        dependenciesFulfilled = function runCaller() { if (!calledRun)
            run(); if (!calledRun)
            dependenciesFulfilled = runCaller; };
        function run() { if (runDependencies > 0) {
            return;
        } preRun(); if (runDependencies > 0) {
            return;
        } function doRun() { var _a; if (calledRun)
            return; calledRun = true; Module["calledRun"] = true; if (ABORT)
            return; initRuntime(); readyPromiseResolve(Module); (_a = Module["onRuntimeInitialized"]) === null || _a === void 0 ? void 0 : _a.call(Module); postRun(); } if (Module["setStatus"]) {
            Module["setStatus"]("Running...");
            setTimeout(function () { setTimeout(function () { Module["setStatus"](""); }, 1); doRun(); }, 1);
        }
        else {
            doRun();
        } }
        if (Module["preInit"]) {
            if (typeof Module["preInit"] == "function")
                Module["preInit"] = [Module["preInit"]];
            while (Module["preInit"].length > 0) {
                Module["preInit"].pop()();
            }
        }
        run();
        moduleRtn = readyPromise;
        return moduleRtn;
    });
})();
exports.default = Module;
