const RewardsInfo = () => {
  return (
    <div className="p-5">
      <section className="flex flex-col gap-6">
        <p>
          You get rewarded for the amount of time spent performing successful
          research sessions.
        </p>
        <p>
          We would like you to visit some websites and use them as you normally
          would. While you do, we use your camera to help us understand how
          people interact with web pages.
        </p>
        <p>
          Your participation helps improve online experiences, and you will be
          compensated for your time. The process is designed to be seamless and
          easy for you—just browse naturally
        </p>
        <p>
          In future we will introduce more ways to earn points so check back
          soon.
        </p>
      </section>
    </div>
  );
};

export default RewardsInfo;
