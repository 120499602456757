import { useOutletContext } from "react-router-dom";
import { FC, useState } from "react";
import { Button, Select, SelectItem } from "@heroui/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { PanelMessages } from "@lumen-developer/lumen-common-js/esm/panel/messages";

import { ErrorClass } from "@/utils/errorFormat";
import { IDomain, startWildRequest } from "@/api/index";
import { useAlertContext } from "@/contexts/alertContext";
import { useSessionErrorContext } from "@/contexts/sessionErrorContext";
import { SessionExternalContext } from "@/types/session";
import extensionSendMessage from "@/utils/extensionCom";

interface IPromptList {
  domain: string;
}

interface PromptListProps {
  availableDomains: IDomain[];
  hasMultipleDomains: boolean;
}

const PromptList: FC<PromptListProps> = ({
  availableDomains,
  hasMultipleDomains,
}) => {
  const {
    broker,
    panelist,
    tmpSessionId,
    panelistId,
    accessCode,
    errorHandle,
    setIsLoading,
  } = useOutletContext<SessionExternalContext>();
  const { handleSubmit, control } = useForm<IPromptList>({
    defaultValues: { domain: availableDomains[0].domain_name },
  });
  const { getError } = useSessionErrorContext();
  const { addAlert } = useAlertContext();
  const [canClick, setCanClick] = useState(true);

  const onSubmit: SubmitHandler<IPromptList> = async ({ domain }) => {
    setIsLoading(true);
    setCanClick(false);

    try {
      broker.saveModel("model", "errorCorrection");
      if (broker.trackersController) {
        await broker.turnOffCamera();
      }
      const model = localStorage.getItem("model");
      const errorCorrection = localStorage.getItem("errorCorrection");

      if (model && errorCorrection) {
        const m: PanelMessages.InitSession = {
          type: PanelMessages.ExternalMessageType.InitSession,
          data: {
            broker: {
              model,
              errorCorrection,
            },
            panelist,
            panelistId,
            tmpSessionId,
            sessionLength: 600000, // TODO: SHOULD BE FROM STATE,
          },
        };
        const canStart = await extensionSendMessage(m);

        if (canStart) {
          await startWildRequest(accessCode, tmpSessionId, panelistId);
          window.location.href = domain as string;
        } else {
          const message = getError(ErrorClass.EXT_INIT_FAIL).safeMessage;
          addAlert({ type: "error", message, timeout: 5 });
          throw new Error(message);
        }
      } else {
        const message = getError(ErrorClass.MISSING_MODEL).safeMessage;
        addAlert({ type: "error", message, timeout: 5 });
        throw new Error(message);
      }
    } catch (e) {
      errorHandle(e);
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {hasMultipleDomains && (
        <Controller
          name="domain"
          control={control}
          render={({ field }) => (
            <Select
              label="Select an website"
              className="mb-5"
              variant="bordered"
              {...field}
              autoFocus
              classNames={{
                trigger: [
                  "data-[focus=true]:border-primary",
                  "data-[open=true]:border-primary",
                ],
              }}
              defaultSelectedKeys={[availableDomains[0].url]}
              disallowEmptySelection
            >
              {availableDomains.map(({ domain_name, url }) => (
                <SelectItem aria-label={`option-${domain_name}`} key={url}>
                  {domain_name}
                </SelectItem>
              ))}
            </Select>
          )}
        />
      )}
      <Button color="secondary" isDisabled={!canClick} type="submit">
        Start Session
      </Button>
    </form>
  );
};

export default PromptList;
