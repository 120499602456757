import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from "@heroui/react";

import Header from "@/components/header/header";
import RewardsTable from "@/components/rewards/rewardsTable";
import Loading from "@/components/loading/loading";
import RewardsInfo from "@/components/rewards/rewardsInfo";
import { useAppSelector } from "@/hooks/store";
import { usePanelistDetails, useRewardReport } from "../api";
import ErrorMessage from "./errorMessage";

const Rewards = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { panelistId, accessCode } = useAppSelector((state) => state.auth);
  const [{ data, loading }] = useRewardReport(accessCode, panelistId);
  const [{ data: panelistDetails, loading: panelistDetailsLoading }] =
    usePanelistDetails(accessCode, panelistId);
  const convertNumber = (amount: number, decimalPlaces: number = 0) => {
    return amount.toLocaleString("en", {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  };

  if (loading || panelistDetailsLoading) return <Loading />;
  if (!data?.success || !panelistDetails?.success) return <ErrorMessage />;

  return (
    <>
      {isMobile ? (
        <Popover placement="bottom">
          <PopoverTrigger>
            <Button
              color="primary"
              variant="light"
              disableRipple
              className="p-0"
            >
              What do you get rewarded for?
            </Button>
          </PopoverTrigger>
          <PopoverContent className="size-lvw">
            <RewardsInfo />
          </PopoverContent>
        </Popover>
      ) : (
        <Tooltip
          content={<RewardsInfo />}
          color="secondary"
          isOpen={isOpen}
          onOpenChange={(open) => setIsOpen(open)}
          onClick={() => setIsOpen(true)}
          classNames={{ content: ["max-w-96"] }}
        >
          <div className="sm:absolute top-5 right-5">
            <Button
              color="primary"
              variant="light"
              disableRipple
              className="p-0 sm:px-5"
            >
              What do you get rewarded for?
            </Button>
          </div>
        </Tooltip>
      )}
      <div>
        <section className="mb-10">
          <Header size="h2">Current saved reward pot</Header>
          <p className="mb-6">{panelistDetails.panel_paragraph}</p>
          <Button color="secondary" onPress={() => null} isDisabled>
            Cash in reward
          </Button>
        </section>
        <Header size="h2">Lifetime rewards</Header>
        <section className="mb-10 flex flex-col gap-4">
          <p>
            In total you have earned {convertNumber(data.total_points)} points.
          </p>
          <div>
            <Button
              type="submit"
              color="secondary"
              onPress={() =>
                setTimeout(() => {
                  navigate("/");
                }, 200)
              }
            >
              Start another session
            </Button>
          </div>
        </section>
        <section className="mb-10">
          <Header size="h2">Session History</Header>
          <RewardsTable data={data?.data_list} />
        </section>
      </div>
    </>
  );
};

export default Rewards;
