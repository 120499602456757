import { FC, ReactNode } from "react";

interface HeaderProps {
  children: ReactNode;
  size?: "h1" | "h2" | "h3";
}

const Header: FC<HeaderProps> = ({ size = "h1", children }) => {
  switch (size) {
    case "h2":
      return <h2 className="text-2xl mb-4 flex gap-2">{children}</h2>;
    case "h3":
      return <h3 className="text-xl mb-3">{children}</h3>;
    case "h1":
    default:
      return <h1 className="text-3xl mb-5">{children}</h1>;
  }
};

export default Header;
