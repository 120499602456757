import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import {
  ErrorClass,
  ErrorFormat,
  GENERIC_SAFE_FMT,
  KNOWN_ERRORS,
} from "@/utils/errorFormat";

export interface IErrorContextProps {
  getError: (eclass: ErrorClass) => ErrorFormat;
  formatError: (error?: any) => ErrorFormat;
}

export const errorContext = createContext({} as IErrorContextProps);

export const useSessionErrorContext = () => {
  const context = useContext(errorContext);
  if (!context || !context.formatError || !context.getError) {
    throw new Error(
      "useSessionErrorContext must be used within an ErrorProvider",
    );
  }
  return context;
};

const SessionErrorContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const memoizedValue = useMemo(() => {
    const getError = (eclass: ErrorClass): ErrorFormat => {
      return KNOWN_ERRORS[eclass];
    };

    const formatError = (error?: any): ErrorFormat => {
      if (typeof error === "string") {
        const found = Object.entries(KNOWN_ERRORS).find(
          ([, ke]) => ke.internalMessage === error,
        );
        if (found) {
          return found[1];
        }
        return { ...GENERIC_SAFE_FMT, internalMessage: error };
      }
      return { ...GENERIC_SAFE_FMT, internalMessage: JSON.stringify(error) };
    };

    return { getError, formatError };
  }, []);

  return (
    <errorContext.Provider value={memoizedValue}>
      {children}
    </errorContext.Provider>
  );
};

export default SessionErrorContextProvider;
