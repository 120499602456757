import { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Button } from "@heroui/react";

import { updateDetails } from "@/store/reducers/auth";
import { useAppSelector } from "@/hooks/store";
import { SessionStartContext } from "@/types/session";
import Loading from "@/components/loading/loading";
import InstallationStepper from "@/components/installationStepper/installationStepper";
import useHandleSession from "@/hooks/useHandleSession";
import useSessionParams from "@/hooks/useSessionParams";
import useCameraPermission from "@/hooks/useCameraPermission";
import { DetailSuccess, usePanelistDetails } from "../../../api";

const SessionStart = () => {
  const {
    broker,
    dispatch,
    panelist,
    panelistId,
    accessCode,
    trackerRef,
    errorHandle,
  } = useOutletContext<SessionStartContext>();
  const [isLoading, setIsLoading] = useState(false);
  const { cameraState, cameraStateIsLoading } = useCameraPermission();
  const { maxAcc, maxPrec, brokerVersionConfig, viewabilityVersionConfig } =
    useAppSelector((state) => state.auth);
  const [
    {
      data: panelistDetails,
      loading: panelistDetailsLoading,
      error: panelistDetailsError,
    },
  ] = usePanelistDetails(accessCode, panelistId);

  useEffect(() => {
    if (!panelistDetails) return;

    const details: DetailSuccess = {
      maxAcc: Math.round(panelistDetails.max_accuracy) || 300,
      maxPrec: Math.round(panelistDetails.max_precision) || 300,
      brokerVersionConfig: panelistDetails.broker_version_config || {},
      viewabilityVersionConfig:
        panelistDetails.viewability_version_config || {},
    };
    dispatch(updateDetails(details));
  }, [panelistDetails, dispatch]);

  useEffect(() => {
    if (panelistDetailsError) {
      errorHandle(panelistDetailsError);
    }
  }, [panelistDetailsError, errorHandle]);

  const {
    cont,
    hasError,
    hasExtension,
    hasExtensionCamera,
    requiresRestart,
    setState,
  } = useHandleSession({
    panelist,
    maxAcc,
    maxPrec,
    brokerVersionConfig,
    viewabilityVersionConfig,
    dispatch,
    setIsLoading,
    cameraState,
    trackerRef,
    errorHandle,
    broker,
  });

  const contRef = useRef(cont);

  useEffect(() => {
    contRef.current = cont;
  }, [cont]);

  const { processParams } = useSessionParams({
    setState,
    cameraStateIsLoading,
    cameraState,
    cont: contRef,
  });
  useEffect(() => {
    processParams();
  }, [processParams]);

  if (panelistDetailsLoading || isLoading) return <Loading showLabel={false} />;

  if (hasError) {
    return (
      <InstallationStepper
        step={(() => {
          if (!hasExtension) {
            return 1;
          }
          if (!hasExtensionCamera) {
            return 2;
          }
          return 3;
        })()}
        cameraState={cameraState}
        cont={contRef}
        requiresRestart={requiresRestart}
      />
    );
  }

  return (
    <>
      <div className="mb-10">
        <Button color="secondary" onPress={cont}>
          Start a 10 minute Research session
        </Button>
      </div>
      <p>Note: We do not record pictures or video of you</p>
    </>
  );
};

export default SessionStart;
