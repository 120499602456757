import { useState } from "react";

import DataDeletionForm from "@/components/dataDeletion/dataDeletionForm";

const DataDeletion = () => {
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  if (deleteSubmitted)
    return (
      <>
        <section className="mb-10">
          <p className="text-2xl mb-5">The Focus Project</p>
        </section>
        <section>
          <p className="mb-4">Your data deletion request has been submitted.</p>
        </section>
      </>
    );

  return <DataDeletionForm setDeleteSubmitted={setDeleteSubmitted} />;
};

export default DataDeletion;
