import { FC } from "react";

interface ErrorMessageProps {
  message?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({
  message = "Something went wrong",
}) => {
  return (
    <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl text-secondary-600">
      {message}
    </p>
  );
};

export default ErrorMessage;
