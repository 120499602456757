import { FC, MutableRefObject, useEffect, useState } from "react";
import { Button } from "@heroui/react";

import { openChromeStore } from "@/utils/helpers";
import Header from "../header/header";
import InstallationIcon from "./installationIcon";
import Loading from "../loading/loading";

interface InstallationStep {
  id: number;
  title: string;
}

export interface InstallationStepperProps {
  step: number;
  done?: boolean;
  cameraState: PermissionState;
  cont: MutableRefObject<() => Promise<void>>;
  requiresRestart: boolean;
}

const installationSteps: InstallationStep[] = [
  {
    id: 1,
    title: "The extension installed on your browser",
  },
  {
    id: 2,
    title: "Camera permission for the extension",
  },
  {
    id: 3,
    title: "Camera permission for the browser",
  },
];

const InstallationStepper: FC<InstallationStepperProps> = ({
  step,
  done = false,
  cameraState,
  cont,
  requiresRestart,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (cameraState === "granted" && step === 3) {
      setIsLoading(true);
      const timeoutId = setTimeout(() => {
        // navigate not used here as refresh is required to prevent 2 video components being created
        if (requiresRestart) {
          window.location.href = "/?autostart=true&browserCamera=true";
        } else {
          cont.current();
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    }

    return undefined;
  }, [cameraState, step, requiresRestart, cont]);

  useEffect(() => {
    if (step === 1) {
      const onFocus = () => {
        window.location.href = "/?autostart=true";
      };

      window.addEventListener("focus", onFocus);

      return () => {
        window.removeEventListener("focus", onFocus);
      };
    }

    return undefined;
  }, [step]);

  const getDetail = () => {
    if (step === 1)
      return "Install extension and allow it access to the camera all the time";
    if (step === 2)
      return "Grant the extension camera permissions to the website and try again";

    return "Grant the browser camera permissions to the website and try again";
  };

  return (
    <>
      <Header size="h2">Participate in a research session</Header>
      <div className="flex flex-col gap-5 mb-8">
        <p>For successful eye tracking we need:</p>
        <ol className="list-decimal list-inside">
          {installationSteps.map(({ title, id }) => (
            <li className="relative py-1" key={id}>
              <span>{title}</span>
              <span className="absolute right-40 top-1/2 transform -translate-y-1/2">
                <InstallationIcon
                  id={id}
                  step={step}
                  done={done}
                  cameraState={cameraState}
                />
              </span>
            </li>
          ))}
        </ol>
      </div>
      {isLoading ? (
        <div className="relative h-20 w-2/3">
          <Loading showLabel={false} />
        </div>
      ) : (
        <>
          <Header size="h2">Next Step:</Header>
          <div className="flex flex-col gap-5">
            <p>{getDetail()}</p>
            <div>
              {step === 1 ? (
                <Button color="success" onPress={openChromeStore}>
                  Install Extension
                </Button>
              ) : (
                <Button
                  color="secondary"
                  onPress={() => {
                    window.location.href = "/?autostart=true";
                  }}
                  data-testid="retry-button"
                >
                  Retry
                </Button>
              )}
            </div>
            <p>Note: We do not record pictures or videos of you</p>
          </div>
        </>
      )}
    </>
  );
};

export default InstallationStepper;
