import React, { FC } from "react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";

interface InstallationIconProps {
  id: number;
  step: number;
  done: boolean;
  cameraState: PermissionState;
}

const InstallationIcon: FC<InstallationIconProps> = ({
  id,
  step,
  done,
  cameraState,
}) => {
  const baseIconProps = "w-6 h-6 inline-block";

  if (id < step || (id === step && done)) {
    return (
      <CheckCircleIcon
        data-testid="check-circle-icon"
        className={`${baseIconProps} text-green-500`}
      />
    );
  }

  if (id === step) {
    if (step === 3) {
      const iconMap = {
        granted: (
          <CheckCircleIcon
            data-testid="check-circle-icon"
            className={`${baseIconProps} text-green-500`}
          />
        ),
        denied: (
          <ExclamationCircleIcon
            data-testid="exclamation-circle-icon"
            className={`${baseIconProps} text-red-500`}
          />
        ),
        prompt: (
          <ExclamationCircleIcon
            data-testid="exclamation-circle-icon"
            className={`${baseIconProps} text-yellow-500`}
          />
        ),
      };
      return (
        iconMap[cameraState] || (
          <ExclamationCircleIcon
            data-testid="exclamation-circle-icon"
            className={`${baseIconProps} text-yellow-500`}
          />
        )
      );
    }

    if (step === 2) {
      return (
        <ExclamationCircleIcon
          data-testid="exclamation-circle-icon"
          className={`${baseIconProps} text-red-500`}
        />
      );
    }
    return (
      <ExclamationCircleIcon
        data-testid="exclamation-circle-icon"
        className={`${baseIconProps} text-yellow-500`}
      />
    );
  }

  return (
    <ExclamationCircleIcon
      data-testid="exclamation-circle-icon"
      className={`${baseIconProps} text-gray-400`}
    />
  );
};

export default InstallationIcon;
