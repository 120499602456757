import { FC } from "react";
import { Spinner } from "@heroui/react";

interface LoadingProps {
  showLabel?: boolean;
  absolutePositioning?: boolean;
  size?: "sm" | "md" | "lg";
}

const Loading: FC<LoadingProps> = ({
  showLabel = true,
  absolutePositioning = true,
  size = "lg",
}) => {
  return (
    <Spinner
      size={size}
      data-testid="loading-spinner"
      className={
        absolutePositioning
          ? "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          : ""
      }
      label={showLabel ? "Loading..." : undefined}
      labelColor="primary"
    />
  );
};

export default Loading;
