import {
  ChangeEvent,
  forwardRef,
  HTMLInputTypeAttribute,
  memo,
  useState,
} from "react";
import { Input as NUIInput } from "@heroui/react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";

export interface InputProps {
  label: string;
  type: HTMLInputTypeAttribute;
  placeholder?: string;
  autoComplete?: string;
  register?: UseFormRegisterReturn<string>;
  errorMessage?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  setFocus?: (name: string) => void;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      type,
      placeholder,
      autoComplete = "off",
      register,
      errorMessage,
      setFocus,
      ...props
    },
    ref,
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const getPasswordIcon = () => {
      return (
        <button
          className="focus:outline-none h-8 w-8 text-secondary"
          type="button"
          onClick={() => {
            setIsPasswordVisible((prev) => !prev);
            if (setFocus && register) {
              setFocus(register.name);
            }
          }}
          aria-label="toggle password visibility"
        >
          {isPasswordVisible ? (
            <EyeSlashIcon data-testid="eye-slash-icon" role="img" />
          ) : (
            <EyeIcon data-testid="eye-icon" role="img" />
          )}
        </button>
      );
    };

    return (
      <NUIInput
        label={label}
        placeholder={placeholder}
        variant="bordered"
        size="sm"
        classNames={{
          inputWrapper: ["group-data-[focus=true]:border-primary"],
        }}
        type={isPasswordVisible && type === "password" ? "text" : type}
        endContent={type === "password" ? getPasswordIcon() : null}
        className="mb-6"
        isInvalid={Boolean(errorMessage)}
        autoComplete={autoComplete}
        errorMessage={errorMessage}
        ref={ref}
        {...register}
        {...props}
      />
    );
  },
);

export default memo(Input);
