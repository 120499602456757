import { useRef, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@heroui/react";

import { SessionSectionContext, SessionRoute } from "@/types/session";
import Header from "@/components/header/header";

const SessionPosition = () => {
  const {
    broker: { trackersController, stopEyePos, getEyePos },
    setStatus,
    status: { position },
  } = useOutletContext<SessionSectionContext>();
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const cont = () => {
    trackersController._videoView.config.colour = "rgba(0,0,0,0)";
    stopEyePos();
    navigate(SessionRoute.CALIBRATION);
  };

  useEffect(() => {
    let throttle = Date.now();

    const goalCoordinates = {
      w: trackersController.config.frameReader.width,
      h: trackersController.config.frameReader.height,
      goalX: Math.round(trackersController.config.frameReader.width / 2),
      goalY: Math.round(trackersController.config.frameReader.height / 2),
    };

    getEyePos((res) => {
      if (Date.now() <= throttle + 500) return;
      let xScore: number;
      let yScore: number;
      const xCenter = (res._right.out.x + res._left.out.x) / 2;
      const yCenter = (res._right.out.y + res._left.out.y) / 2;

      if (xCenter > goalCoordinates.goalX) {
        xScore = 100 - (xCenter / goalCoordinates.goalX - 1) * 100;
      } else {
        xScore = (xCenter / goalCoordinates.goalX) * 100;
      }

      if (yCenter > goalCoordinates.goalY) {
        yScore = 100 - (yCenter / goalCoordinates.goalY - 1) * 100;
      } else {
        yScore = (yCenter / goalCoordinates.goalY) * 100;
      }

      const lowestScore = () => {
        if (xScore > yScore) {
          return Math.round(yScore);
        }
        return Math.round(xScore);
      };

      const final = lowestScore();

      setStatus((value) => ({ ...value, position: final }));
      throttle = Date.now();
    });
  }, [getEyePos, setStatus, trackersController]);

  return (
    <>
      <Header size="h2">Step 2: Head Position</Header>
      <div className="flex flex-col gap-5 mb-8">
        <p>Your head position is important.</p>
        <p>To use the data a head position score of 80 plus is needed.</p>
        <p>
          Throughout the study plase make sure your head is in view of the
          camera.
        </p>
      </div>
      <Button
        color="secondary"
        onPress={cont}
        isDisabled={position < 80}
        ref={buttonRef}
      >
        Continue
      </Button>
    </>
  );
};

export default SessionPosition;
