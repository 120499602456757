import { FC, useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Button, Tooltip } from "@heroui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch } from "@/hooks/store";
import { useAlertContext } from "@/contexts/alertContext";
import { logIn } from "@/store/reducers/auth";
import { changePassword } from "@/api/index";
import { PasswordStrengthBar } from "./passwordStrengthBar";
import Input from "../input/input";
import ExternalHeader from "../header/externalHeader";

interface PasswordFormProps {
  title: string;
}

const PasswordForm: FC<PasswordFormProps> = ({ title }) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const link = searchParams.get("link");
  const navigate = useNavigate();
  const defaultValues = { password: "" };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setFocus,
  } = useForm<{ password: string }>({ defaultValues });
  const watchPassword = useWatch({
    control,
    name: "password",
  });
  const [passwordScore, setPasswordScore] = useState(0);
  const { addAlert } = useAlertContext();

  const onSubmit = async ({ password }) => {
    const isCreatePassword = title === "Create Password";
    try {
      const {
        success,
        access_code: accessCode,
        panelist_id: panelistId,
        panelist_reference: panelist,
      } = await changePassword(password, link, isCreatePassword);
      if (success) {
        dispatch(
          logIn({
            accessCode,
            panelistId,
            panelist,
          }),
        );
        navigate("/");
      }
    } catch (e) {
      addAlert({
        message: e?.message,
        type: "error",
        timeout: 5,
      });
    }
  };

  useEffect(() => {
    setFocus("password");
  }, [setFocus]);

  return (
    <>
      <ExternalHeader
        title={
          <>
            <p>{title}</p>
            <Tooltip
              content={
                <div>
                  <p>Your password must be at least 8 characters long.</p>
                  <p>
                    To achieve a stronger password, use a mix of uppercase and
                    lowercase letters, numbers, and special characters.
                  </p>
                </div>
              }
              color="secondary"
            >
              <InformationCircleIcon className="h-5 w-5 cursor-pointer" />
            </Tooltip>
          </>
        }
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Password"
          type="password"
          register={register("password", {
            validate: () => {
              if (passwordScore < 1) {
                return "Password is too short";
              }
              if (passwordScore < 2) {
                return "Password is not strong enough";
              }

              return undefined;
            },
          })}
          errorMessage={errors?.password?.message}
        />
        <PasswordStrengthBar
          {...{ password: watchPassword, passwordScore, setPasswordScore }}
        />
        <div className="flex items-center justify-content gap-9">
          <Button type="submit" color="secondary">
            Confirm
          </Button>
        </div>
      </form>
    </>
  );
};

export default PasswordForm;
