/* eslint-disable react/no-unescaped-entities */
import { Dispatch, FC, SetStateAction } from "react";

import { Button } from "@heroui/react";
import Header from "../header/header";

interface EmailConfirmationProps {
  setEmail: Dispatch<SetStateAction<string>>;
  email: string;
}

const EmailConfirmation: FC<EmailConfirmationProps> = ({ setEmail, email }) => {
  return (
    <>
      <Header>The Focus Project</Header>
      <p className="mb-4">
        An email has been sent to {email}. Please verify your email address and
        set up your password.
      </p>
      <p className="mb-6">
        Not arrived yet? Don't forget ot check you spam folder.
      </p>
      <Button color="secondary" onPress={() => setEmail("")}>
        Re-enter email
      </Button>
    </>
  );
};

export default EmailConfirmation;
