"use client";
import {
  usePopoverContext
} from "./chunk-CGIRYUEE.mjs";

// src/popover-trigger.tsx
import { Children, cloneElement, useMemo } from "react";
import { pickChildren } from "@heroui/react-utils";
import { useAriaButton } from "@heroui/use-aria-button";
import { Button } from "@heroui/button";
import { mergeProps } from "@react-aria/utils";
import { jsx } from "react/jsx-runtime";
var PopoverTrigger = (props) => {
  var _a;
  const { triggerRef, getTriggerProps } = usePopoverContext();
  const { children, ...otherProps } = props;
  const child = useMemo(() => {
    if (typeof children === "string") return /* @__PURE__ */ jsx("p", { children });
    return Children.only(children);
  }, [children]);
  const childRef = (_a = child.props.ref) != null ? _a : child.ref;
  const { onPress, isDisabled, ...restProps } = useMemo(() => {
    return getTriggerProps(mergeProps(otherProps, child.props), childRef);
  }, [getTriggerProps, child.props, otherProps, childRef]);
  const [, triggerChildren] = pickChildren(children, Button);
  const { buttonProps } = useAriaButton({ onPress, isDisabled }, triggerRef);
  const hasHeroUIButton = useMemo(() => {
    return (triggerChildren == null ? void 0 : triggerChildren[0]) !== void 0;
  }, [triggerChildren]);
  if (!hasHeroUIButton) {
    delete restProps["preventFocusOnPress"];
  }
  return cloneElement(
    child,
    mergeProps(restProps, hasHeroUIButton ? { onPress, isDisabled } : buttonProps)
  );
};
PopoverTrigger.displayName = "HeroUI.PopoverTrigger";
var popover_trigger_default = PopoverTrigger;

export {
  popover_trigger_default
};
